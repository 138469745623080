import Slider from '@/components/molecules/Slider';
import { ActionBlockData } from '@/services/types/blocksTypes';
import { getImage } from '@/services/utils/helper';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const ActionHorizontal: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className='container'>
			{/* <div className='action_horizontal_container'> */}

			<Slider
				slidesPerView={5}
				spaceBetween={20}
				pagination={false}
				shouldSlidesPerGroup={true}
				responsive={{
					0: {
						slidesPerView: 2,
						spaceBetween: 20
					},

					768: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					800: {
						slidesPerView: 2,
						spaceBetween: 20
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 10
					},
					1200: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					1280: {
						slidesPerView: 3,
						spaceBetween: 20
					},
					1400: {
						slidesPerView: 5,
						spaceBetween: 20
					},
					1600: {
						slidesPerView: 5,
						spaceBetween: 20
					}
				}}
				className={'product-slider action_horizontal_container'}>
				{data?.map((d: ActionBlockData, i: number) => {
					return (
						<div
							key={i}
							className={`warp cursor-pointer h-[100%]`}
							style={{
								background: `linear-gradient(158deg, rgba(175, 147, 252, 0.00) 2.71%, ${d?.color_code} 85.56%)`
							}}>
							<div className='content'>
								<Link href={children[i]?.link} style={{ textDecoration: 'none' }}>
									<h3 className='text-grey900 '>{d?.title}</h3>
									<div
										className='text-grey900'
										style={{
											lineHeight: '20px'
										}}>
										{d.subtitle.split('|').map((d: string, i: number) => (
											<span key={i} className=''>
												{d}
											</span>
										))}
									</div>
								</Link>
								<div className='icon_wrap'>
									<Image alt={d?.title} src={getImage(d.file)} width={80} height={80} priority unoptimized />
								</div>
							</div>
						</div>
					);
				})}
			</Slider>
			{/* {data?.map((d: ActionBlockData, i: number) => {
					return (
						
						<div
							key={i}
							className={`warp cursor-pointer`}
							style={{
								background: `linear-gradient(158deg, rgba(175, 147, 252, 0.00) 2.71%, ${d?.color_code} 85.56%)`
							}}>
							<div className='content'>
								<Link href={children[i]?.link} style={{ textDecoration: 'none' }}>
									<h3 className='text-grey900 ' >{d?.title}</h3>
									<div className='text-grey900' style={{
										lineHeight:"20px"
									}}>
										{d.subtitle.split('|').map((d: string, i: number) => (
											<span key={i} className=''>{d}</span>
										))}
									</div>
								</Link>
								<div className='icon_wrap'>
									<Image alt={d?.title} src={getImage(d.file)} width={80} height={80} priority unoptimized />
								</div>
							</div>
						</div>
					);
				})} */}
			{/* </div> */}
		</div>
	);
};

export default ActionHorizontal;
