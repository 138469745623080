import Button from '@/components/atoms/Button';
import Slider from '@/components/molecules/Slider';
import { ActionBlockData } from '@/services/types/blocksTypes';
import { getImage, hexToRGB, hexToRgbaWithOpacity } from '@/services/utils/helper';
import cls from 'classnames';
import Image from 'next/image';
import React from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const HealthSupplements: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('pb-30 ', styles.health_supplements)}>
			<div className='container product-slider'>
				<div
					className='text-center '
					style={{
						textAlign: 'center',
						marginBottom: 30
					}}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<Slider
					slidesPerView={2.5}
					spaceBetween={10}
					shouldSlidesPerGroup={true}
					responsive={{
						'0': {
							slidesPerView: 0.5
						},
						'640': {
							slidesPerView: 0.5
						},
						'768': {
							slidesPerView: 1.3,
							spaceBetween: 10
						},
						'800': {
							slidesPerView: 1.3,
							spaceBetween: 10
						},
						'1024': {
							slidesPerView: 1.3,
							spaceBetween: 10
						},
						'1280': {
							slidesPerView: 1.6,
							spaceBetween: 10
						},
						'1400': {
							slidesPerView: 2.5,
							spaceBetween: 10
						},
						'1600': {
							slidesPerView: 2.5,
							spaceBetween: 10
						}
					}}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div
								style={{
									backgroundColor: `rgba(${hexToRGB(d?.color_code)}, 0.1)`
								}}
								key={i}
								className={styles.card}>
								<div className={styles.card__content}>
									<div className={styles.card__left}>
										<div className='text-black text-24 text-grey900 fw-700'>{d?.title}</div>
										<div className='w-full'>
											<Link href={children[i]?.link || '/'}>
												<Button
													className={'text-18 text-grey900 fw-500' + ' ' + styles.card__button}
													style={{
														width: '100%',
														backgroundColor: `${d?.color_code}`,
														borderRadius: 6
													}}
													size='large'
													label={<span className='fw-700'>{d?.subtitle}</span> || ''}
													type='secondary'
												/>
											</Link>
										</div>
									</div>
									<div
										className={styles.card__right}
										style={{
											backgroundColor: hexToRgbaWithOpacity(d?.color_code, 0.3)
										}}>
										<Image
											alt={d?.title || ''}
											src={getImage(d.file)}
											width={188}
											height={188}
											style={{
												marginTop: 10,
												width: 155,
												height: 188,
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
											priority
											unoptimized
										/>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default HealthSupplements;
